export const logoblack = ['190 80', `
<title>Whale</title>

<g transform="translate(0.000000,73.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M484 708 c-16 -4 -41 -19 -54 -33 -21 -22 -22 -28 -10 -35 11 -7 7
-10 -16 -10 -33 0 -74 -35 -74 -62 0 -9 8 -26 19 -39 18 -23 42 -26 126 -17
25 2 31 -1 33 -20 5 -33 26 -21 30 17 l3 32 36 -22 c69 -43 172 -10 173 54 0
24 -53 77 -76 77 -10 0 -30 12 -46 27 -31 29 -96 44 -144 31z m114 -44 c12 -8
22 -18 22 -23 0 -4 18 -13 40 -20 64 -19 67 -80 4 -95 -19 -5 -49 7 -124 50
-55 31 -100 60 -100 64 0 12 26 31 57 40 33 9 69 3 101 -16z m-137 -80 c17 -8
40 -17 51 -19 27 -6 30 -9 22 -28 -5 -13 -10 -14 -25 -6 -11 5 -16 15 -13 21
5 7 2 9 -7 6 -82 -30 -88 -32 -103 -19 -16 14 -21 42 -9 54 11 12 53 8 84 -9z"/>

<path d="M1300 500 c-14 -22 -27 -40 -30 -40 -3 0 -82 -14 -175 -30 -170 -30
-170 -30 -345 -19 -124 9 -180 16 -192 27 -13 11 -26 12 -65 4 -36 -8 -65 -7
-113 3 -61 13 -331 39 -334 33 0 -2 -11 -15 -24 -29 l-23 -27 103 -82 c112
-91 179 -136 246 -165 30 -13 44 -26 49 -44 9 -42 75 -101 113 -101 15 0 12
21 -5 34 -8 6 -17 25 -21 42 l-6 31 54 -5 c42 -4 62 -12 96 -41 63 -52 73 -58
115 -76 37 -15 38 -15 28 4 -6 11 -20 23 -31 26 -24 8 -24 12 -4 60 19 46 34
33 34 -29 0 -25 6 -43 18 -52 18 -14 92 -19 92 -6 0 4 -20 28 -45 52 -55 54
-58 76 -11 77 41 1 252 74 319 111 28 15 79 54 115 88 l66 61 47 -18 c40 -16
53 -17 81 -6 52 19 82 43 74 62 -5 14 -9 14 -33 2 -16 -8 -44 -12 -66 -9 -34
4 -41 10 -63 53 -13 27 -28 49 -32 49 -4 0 -18 -18 -32 -40z m53 -22 c6 -18
10 -36 9 -40 -1 -5 1 -8 6 -9 16 -1 38 -8 62 -17 22 -9 23 -11 5 -13 -29 -2
-49 2 -55 11 -3 5 -19 10 -35 13 -17 2 -38 7 -47 11 -21 10 -13 30 9 22 8 -3
11 -2 8 4 -5 8 14 50 23 50 2 0 8 -15 15 -32z m-1093 -34 l35 -6 -28 -15 c-16
-8 -27 -17 -24 -20 3 -2 19 3 35 11 19 10 47 14 74 12 24 -2 49 -4 56 -5 41
-3 -71 -61 -118 -61 -16 0 -30 -4 -30 -10 0 -5 10 -10 23 -10 12 0 50 -9 84
-19 34 -10 89 -22 123 -26 51 -6 60 -5 60 9 0 20 9 20 34 1 18 -14 18 -15 -7
-45 -14 -16 -28 -26 -32 -21 -3 5 -1 12 5 16 5 3 10 13 10 21 0 12 -4 12 -20
4 -19 -10 -31 -8 -275 46 -56 12 73 -36 150 -57 50 -12 101 -29 114 -35 26
-14 61 0 61 23 0 7 5 13 10 13 14 0 13 -6 -12 -45 -13 -19 -31 -35 -40 -35 -9
0 -23 -8 -30 -19 -18 -25 -58 -37 -58 -17 0 12 -5 14 -25 6 -16 -6 -26 -6 -31
1 -4 6 -25 19 -48 30 l-41 18 53 -1 c77 -1 74 9 -5 17 -50 5 -80 15 -116 37
-26 17 -46 32 -44 35 3 2 51 -12 108 -30 117 -39 208 -62 215 -54 5 5 -103 44
-143 51 -11 3 -51 14 -89 25 -37 12 -80 19 -94 16 -19 -4 -32 1 -50 20 -13 14
-22 26 -19 28 2 2 25 14 52 26 26 13 47 27 47 31 0 5 -25 -3 -57 -17 -49 -22
-59 -24 -77 -13 -12 8 -34 20 -49 28 -71 36 74 60 213 36z m1033 -20 c3 -3
-22 -31 -56 -61 l-62 -55 -7 38 c-12 69 -11 72 30 77 67 7 89 7 95 1z m-798
-14 c3 -5 25 -10 48 -11 23 0 59 -3 80 -7 l38 -7 -34 -34 -35 -33 -52 41 c-28
23 -58 41 -66 41 -8 0 -14 5 -14 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m640
-37 c3 -21 8 -48 11 -61 4 -20 -3 -26 -51 -48 -31 -15 -59 -23 -62 -20 -3 3
-25 34 -48 68 l-42 63 86 16 c47 9 89 17 93 18 4 1 10 -16 13 -36z m-644 -10
c27 -20 49 -39 49 -41 0 -8 -114 7 -149 19 l-34 12 39 23 c22 13 41 24 43 24
2 0 26 -17 52 -37z m387 12 c3 -3 -161 -134 -184 -148 -6 -3 -26 12 -44 34
l-32 41 42 43 42 43 86 -5 c48 -3 88 -7 90 -8z m47 -5 c-5 -8 67 -127 86 -140
3 -3 -54 -6 -128 -7 -100 -1 -138 -5 -144 -14 -12 -19 -7 -20 47 -10 67 14
184 20 184 10 0 -4 -15 -11 -32 -14 -18 -4 -37 -11 -43 -15 -5 -4 -31 -11 -57
-14 -26 -4 -50 -11 -53 -16 -3 -6 -16 -5 -31 0 -23 9 -27 7 -42 -30 -9 -22
-19 -40 -22 -39 -3 0 -20 13 -38 28 l-33 29 32 22 c25 19 35 21 50 12 11 -7
27 -8 41 -3 22 8 21 9 -9 15 -41 9 -50 22 -21 32 24 7 175 121 198 149 14 17
25 21 15 5z m-277 -146 c15 -15 14 -18 -13 -40 -36 -28 -41 -29 -49 -7 -4 10
2 25 15 40 27 27 26 27 47 7z m-88 -54 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14
10 3 6 13 10 21 10 8 0 14 -4 14 -10z m-116 -26 c9 -4 16 -17 16 -33 l-1 -26
-19 24 c-29 34 -27 47 4 35z m36 -74 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m366 -47 c0 -1 -13 1 -28 4 -19 4 -28 11 -28
25 0 19 1 18 29 -4 15 -13 28 -24 27 -25z"/>

<path d="M60 425 c7 -9 30 -11 78 -8 99 7 97 21 -2 21 -69 0 -85 -3 -76 -13z"/>
<path d="M281 398 c-36 -8 -42 -12 -28 -19 20 -12 79 -2 104 17 17 12 16 13
-7 13 -14 -1 -45 -6 -69 -11z"/>
</g>

`]
